//import am4geodata_usaLow from '@amcharts/amcharts5-geodata/usaLow';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
// import * as am4geodata_data from '@amcharts/amcharts5-geodata/data/countries2';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import React, { useEffect, useState } from 'react';
import { cleanCountry } from '../../utilities/Country';
import { CountryObj } from '../../features/countries/countries';

am4core.useTheme(am4themes_animated);

//export default function MapChartComponent({props : row}): JSX.Element {
const MapChartComponent: React.FC<{ row: CountryObj }> = ({
    row,
}): JSX.Element => {
    const countryClean = cleanCountry(row);
    const countryTitle = row.Country;

    const chart = React.useRef<any>(null);

    const [library, setLibrary] = useState({ default: { feature: {} } });
    const [init, setInit] = useState(false);

    useEffect(() => {
        const fetchGeodata = async () => {
            // Dynamically import the library based on the prop
            try {
                const dynamicLibrary = await import(
                    `@amcharts/amcharts5-geodata/${countryClean}Low`
                );
                setTimeout(() => {
                    setLibrary(dynamicLibrary);
                    setInit(true);
                }, 200);
            } catch (error) {
                console.error('Error loading library:', error);
            }
        };

        fetchGeodata();
    }, [countryClean]);

    useEffect(() => {
        if (init) {
            const x = am4core.create('chartdiv', am4maps.MapChart);

            // Set map definition
            x.geodata = library.default; // Access the module using .default

            // Set projection
            x.projection = new am4maps.projections.Miller();

            // Create map polygon series
            const polygonSeries = x.series.push(new am4maps.MapPolygonSeries());

            // Exclude Alaska and Hawaii
            polygonSeries.exclude = ['US-AK', 'US-HI'];

            // Set up series data
            polygonSeries.useGeodata = true;

            // Configure series
            const polygonTemplate = polygonSeries.mapPolygons.template;
            polygonTemplate.tooltipText = '{name}';
            polygonTemplate.fill = am4core.color('#74B266'); // Map color

            // Add zoom control
            x.zoomControl = new am4maps.ZoomControl();

            chart.current = x;

            return () => {
                x.dispose();
            };
        }
    }, [init, library]);

    return (
        <>
            <div style={{ minWidth: window.innerWidth - 75, padding: 5 }}>
                {countryTitle}
                <div
                    id="chartdiv"
                    style={{ width: '100%', height: '500px' }}
                ></div>
            </div>
        </>
    );
};

export default MapChartComponent;
