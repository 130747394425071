import { dialogParams, rand } from '../../utilities/gen';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { apiPost } from '../../utilities/ApiRequest';
import { CountriesState } from '../../features/countries/countries';
import { SessionState } from '../../features/session/session';
import { setCountries } from '../../features/countries/countriesSlice';
import { addSnackbarMsg } from '../../features/snackbar/snackbarSlice';
import { setDialog } from '../../features/dialog/dialogSlice';

interface TextFieldObj {
    [key: string]: string;
}

interface DetailObj {
    _id: string;
    name: string;
    description: string;
    // Add an index signature that allows you to use a string to index into DetailObj
    [key: string]: any;
}

export default function (props: { country: any }): JSX.Element {
    const { country } = props;
    const countries: CountriesState = useAppSelector(
        (state: any) => state.countries
    );
    const session: SessionState = useAppSelector((state) => state.session);
    const token = session.user.token;
    const dis = useAppDispatch();

    const saveChanges = async () => {
        const textFields = document.querySelectorAll(
            'input[type="text"], textarea'
        ); // select all text fields and text areas
        const countryObj: any = {};

        textFields.forEach((textField) => {
            let txtId = textField.getAttribute('id'); // get the text field's ID
            if (txtId?.toString().includes('txt')) {
                if (txtId) {
                    const val = document.getElementById(
                        txtId
                    ) as HTMLInputElement;
                    txtId = txtId.replace('txt', '');
                    countryObj[txtId] = val.value;
                }
            }
        });

        let newCountries = [];
        newCountries = [...countries.arr];
        // delete from the array the lake object that is being edited
        newCountries = newCountries.filter(
            (e: any) => e._id !== countryObj._id
        );
        //add the edited lake object to the array
        newCountries.push(countryObj);
        dis(setCountries(newCountries));
        const _id = countryObj._id;
        const collection = 'countries';
        const res = await apiPost('/doc_edit_by_id', {
            token,
            collection,
            changeObj: countryObj,
            _id,
        });

        const severity = res.data.err ? 'error' : 'success';
        const msg = res.data.msg
            ? res.data.msg
            : `Country ${countryObj.Country} updated `;
        setTimeout(() => dis(addSnackbarMsg({ msg, severity })), 500);

        // close the dialog
        dis(setDialog(dialogParams(false, '', '', {})));
    };

    return (
        <>
            <div style={{ minWidth: 300, margin: 1, padding: 2 }}>
                Details
                {Object.keys(country).map((key) => {
                    return (
                        <div key={'k' + rand()} style={{ padding: 5 }}>
                            <TextField
                                id={'txt' + key}
                                label={key}
                                variant="outlined"
                                defaultValue={country[key]}
                                size="small"
                                fullWidth
                            >
                                {country[key]}
                            </TextField>
                        </div>
                    );
                })}
                <Button variant="contained" fullWidth onClick={saveChanges}>
                    Save
                </Button>
            </div>
        </>
    );
}
