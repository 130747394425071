import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Tooltip from '@mui/material/Tooltip';
// import { addSnackbarMsg } from '../../features/snackbar/snackbarSlice';
import { dialogParams, msg, rand } from '../../utilities/gen';
import { setDialog } from '../../features/dialog/dialogSlice';

import Loading from '../../components/Loading';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import { setUsers } from '../../features/users/usersSlice';
import { UserObj, UsersArrType, UsersState } from '../../features/users/users';
import { addSnackbarMsg } from '../../features/snackbar/snackbarSlice';

interface Column {
    id: any;
    label: string | any;
    minWidth?: number | any;
}

const columns: readonly Column[] = [
    { id: '_id', label: 'ID', minWidth: 50 },
    { id: 'email', label: 'Email', minWidth: 70 },
    {
        id: 'firstName',
        label: 'First',
        minWidth: 50,
    },
    {
        id: 'lastName',
        label: 'Last',
        minWidth: 50,
    },
];

export default React.memo((): JSX.Element => {
    const dis = useAppDispatch();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [editedRowId, setEditedRowId] = React.useState<string | null>(null);
    const users: UsersState = useAppSelector((state: any) => state.users);

    const rows: any = users && users.arr[0] ? users.arr : [];

    const useStyles = makeStyles({
        editedRow: {
            transition: 'background-color 0.5s',
            backgroundColor: '#cccc99', // Initial background color
        },
    });

    // Use the useStyles hook to get the class names
    const classes = useStyles();

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDetails = (row: UserObj, num: string) => {
        const { id } = row;

        dis(addSnackbarMsg(msg(`Fetching user (${id}) details`, 'info')));
        dis(addSnackbarMsg(msg(`Processing User ID: ${id}`, 'success')));

        dis(
            setDialog(
                dialogParams(true, 'User: ' + id, 'UseDetails', {
                    id,
                    num,
                })
            )
        );
        setEditedRowId(row._id);
    };

    const handleEdit = (row: UserObj) => {
        const { _id } = row;

        dis(addSnackbarMsg(msg(`Edit ${_id}`, 'info')));
        dis(
            setDialog(
                dialogParams(true, `Edit ${_id}`, 'UseEdit', {
                    row,
                    table: 'User',
                    uid: '_id',
                })
            )
        );
        setEditedRowId(row._id);
    };

    const handleDelete = (row: UserObj) => {
        if (window.confirm('Are you sure you want to delete this?')) {
            dis(
                setUsers({
                    arr: users.arr.filter((e: UserObj) => e._id !== row._id),
                })
            );
            dis(
                addSnackbarMsg(
                    msg(
                        `Delete User ${row.firstName} ${row.lastName} `,
                        'warning'
                    )
                )
            );
        } else {
            dis(addSnackbarMsg(msg(`Not Deleting User`, 'info')));
            return false;
        }
    };

    return (
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer sx={{ minHeight: 540 }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                            <TableCell>Details</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!rows[0] ? (
                            <TableCell colSpan={7}>
                                <Loading name="Users" />
                            </TableCell>
                        ) : (
                            rows
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row: UserObj) => {
                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row._id}
                                            className={
                                                editedRowId === row._id
                                                    ? 'edited-row'
                                                    : ''
                                            }
                                        >
                                            {columns.map((column: Column) => {
                                                return (
                                                    <TableCell key={column.id}>
                                                        {column.id === 'id' ? (
                                                            <Typography color="primary.main">
                                                                {
                                                                    row[
                                                                        column.id as keyof UserObj
                                                                    ]
                                                                }{' '}
                                                                {/* Use 'as keyof UserObj' to index by known keys */}
                                                            </Typography>
                                                        ) : (
                                                            <Typography color="text.secondary">
                                                                {
                                                                    row[
                                                                        column.id as keyof UserObj
                                                                    ]
                                                                }{' '}
                                                                {/* Use 'as keyof UserObj' to index by known keys */}
                                                            </Typography>
                                                        )}
                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell>
                                                <Tooltip title="Edit">
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() =>
                                                            handleEdit(row)
                                                        }
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Edit">
                                                    <IconButton
                                                        color="primary"
                                                        onClick={() =>
                                                            handleDelete(row)
                                                        }
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
});
