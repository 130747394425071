import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { SessionState } from './session';
import { fetchSession } from './sessionAPI';

const initialState: SessionState = {
    darkMode: false,
    loginDisplay: 0,
    loginDisplayLastClicked: 0,
    speed: 0.3,
    status: 'idle',
    user: {
        token: '',
        email: '',
        displayName: '',
    },
};

export const addAsync = createAsyncThunk(
    './fetchSession',
    async (amount: number) => {
        const response = await fetchSession(amount);
        return response.data;
    }
);

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setSession: (state, action: PayloadAction<SessionState>) => {
            try {
                //state = action.payload;
                state.darkMode = action.payload.darkMode;
                state.loginDisplay = action.payload.loginDisplay;
                state.loginDisplayLastClicked =
                    action.payload.loginDisplayLastClicked;
                state.speed = action.payload.speed;
                state.status = action.payload.status;
                state.user = action.payload.user;
            } catch (error) {
                console.log(error);
            }
        },
        clearSession: (state) => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(addAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(addAsync.fulfilled, (state, action) => {
                state.status = 'idle';
                //state.value += action.payload;
            })
            .addCase(addAsync.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export const { setSession, clearSession } = sessionSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.session.value)`
export const selectSession = (state: RootState) => state.session;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const sessionInit =
    (user: any): AppThunk =>
    (dispatch) => {
        dispatch(setSession(user));
    };

export default sessionSlice.reducer;
