import { rand } from '../../../utilities/gen';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';

export default function (props: { lake: any }): JSX.Element {
    const { lake } = props;
    return (
        <>
            Details
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <Table>
                    <TableContainer>
                        {
                            // iteriate over the lake object and display the data
                            Object.keys(lake).map((key) => {
                                return (
                                    <TableRow key={'k' + rand()}>
                                        <TableCell>{key}</TableCell>
                                        <TableCell>{lake[key]}</TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableContainer>
                </Table>
            </Paper>
        </>
    );
}
