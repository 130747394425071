import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { LakeState } from '../../pages/Lakes/types';

const initialState: LakeState = {
    arr: [],
};

export const lakesSlice = createSlice({
    name: 'lakes',
    initialState,

    reducers: {
        setLakes: (state: LakeState, action: PayloadAction<any>) => {
            try {
                state.arr = action.payload;
            } catch (error) {
                console.log(error);
            }
        },
        clearLakes: () => initialState,
    },
});

export const { setLakes, clearLakes } = lakesSlice.actions;

export const selectLakes = (state: RootState) => state.lakes;

export default lakesSlice.reducer;
