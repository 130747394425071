import { msg, rand } from '../../../utilities/gen';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { LakeState } from '../types';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setLakes } from '../../../features/lakes/lakesSlice';
import { apiPost } from '../../../utilities/ApiRequest';
import { addSnackbarMsg } from '../../../features/snackbar/snackbarSlice';

interface TextFieldObj {
    [key: string]: string;
}

interface DetailObj {
    _id: string;
    name: string;
    description: string;
    // Add an index signature that allows you to use a string to index into DetailObj
    [key: string]: any;
}

export default function (props: { lake: any }): JSX.Element {
    const lakes: LakeState = useAppSelector((state: any) => state.lakes);
    const session: any = useAppSelector((state) => state.session);
    const token = session.user.token;
    const dis = useAppDispatch();

    const saveChanges = async () => {
        const textFields = document.querySelectorAll(
            'input[type="text"], textarea'
        ); // select all text fields and text areas
        const lakeObj: any = {};

        textFields.forEach((textField) => {
            let txtId = textField.getAttribute('id'); // get the text field's ID
            if (txtId?.toString().includes('txt')) {
                if (txtId) {
                    const val = document.getElementById(
                        txtId
                    ) as HTMLInputElement;
                    txtId = txtId.replace('txt', '');
                    lakeObj[txtId] = val.value;
                }
            }
        });

        let newLakes = [];
        newLakes = [...lakes.arr];
        // delete from the array the lake object that is being edited
        newLakes = newLakes.filter((e) => e._id !== lakeObj._id);
        // add the edited lake object to the array
        newLakes.push(lakeObj);
        dis(setLakes(newLakes));
        const _id = lakeObj._id;
        const res: any = await apiPost('/lakes_edit_obj', {
            token,
            lakeObj,
            _id,
        });
        console.log(res.data);
        dis(
            addSnackbarMsg(
                msg(
                    `Lake ${lakeObj.lName} updated `,
                    res.data.err ? 'error' : 'success'
                )
            )
        );
    };

    return (
        <>
            Details
            <div>
                {Object.keys(props.lake).map((key) => {
                    return (
                        <div key={'k' + rand()} style={{ padding: 5 }}>
                            <TextField
                                id={'txt' + key}
                                label={key}
                                variant="outlined"
                                defaultValue={props.lake[key]}
                                size="small"
                                fullWidth
                            >
                                {props.lake[key]}
                            </TextField>
                        </div>
                    );
                })}
                <Button variant="contained" fullWidth onClick={saveChanges}>
                    Save
                </Button>
            </div>
        </>
    );
}
