import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { LakeObj, LakeState } from './types';
import { addSnackbarMsg } from '../../features/snackbar/snackbarSlice';
import { dialogParams, msg, rand } from '../../utilities/gen';
import { setDialog } from '../../features/dialog/dialogSlice';
import { Button, ButtonGroup, TextField } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import MapIcon from '@mui/icons-material/Map';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import { setLakes } from '../../features/lakes/lakesSlice';
import Flag from '../../widgets/Flag';
import { SessionState } from '../../features/session/session';

export default React.memo((): JSX.Element => {
    const dis = useAppDispatch();
    const session: SessionState = useAppSelector(
        (state: { session: any }) => state.session
    );
    const lakes: LakeState = useAppSelector((state: any) => state.lakes);
    const [page, setPage] = React.useState(0);
    const [filtered, setFiltered] = React.useState<LakeObj[]>(lakes.arr);
    const [rowsPerPage, setRowsPerPage] = React.useState(30);

    const handleSearchLake = (search: string) => {
        setFiltered(
            search === ''
                ? lakes.arr
                : lakes.arr.filter((e) => e.lName.includes(search))
        );
    };

    const handleSearchCountry = (search: string) => {
        setPage(0);
        setFiltered(
            search === ''
                ? lakes.arr
                : lakes.arr.filter((e) => e.countries.includes(search))
        );
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleDetails = (row: LakeObj) => {
        const { _id, lName } = row;

        dis(addSnackbarMsg(msg(`Fetching ${lName} details`, 'info')));
        setTimeout(
            () => {
                dis(addSnackbarMsg(msg(`Processing ${lName}`, 'success')));
            },
            session.speed * 1.1 * 1000
        );
        dis(
            setDialog(
                dialogParams(true, 'Lake: ' + lName, 'Details', {
                    _id,
                    lName,
                })
            )
        );
    };

    const handleEdit = (row: LakeObj) => {
        const { _id, lName } = row;

        dis(addSnackbarMsg(msg(`Edit ${lName}`, 'info')));
        dis(
            setDialog(
                dialogParams(true, 'Lake: ' + _id, 'Edit', {
                    _id,
                    lName,
                })
            )
        );
    };

    const handleDelete = (row: LakeObj) => {
        if (window.confirm('Are you sure you want to delete this?')) {
            dis(
                setLakes({
                    ...lakes,
                    arr: lakes.arr.filter((e) => e.id !== row.id),
                })
            );
            dis(addSnackbarMsg(msg(`Delete User ${row.id} `, 'warning')));
        } else {
            dis(addSnackbarMsg(msg(`Not Deleting User`, 'info')));
            return false;
        }
    };

    const handleMap = (row: LakeObj) => {
        const { lName } = row;

        dis(addSnackbarMsg(msg(`Fetching ${lName} details`, 'info')));
        dis(
            setDialog(
                dialogParams(true, 'Lake: ' + lName, 'MapLakeGraphic', { row })
            )
        );
    };
    React.useEffect(() => {
        // refresh the list
        console.log('UE Lakes Display');
    }, [lakes]);
    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TextField
                    label="Search Lake"
                    variant="outlined"
                    size="small"
                    sx={{ p: 1, m: 1 }}
                    onChange={(e) => handleSearchLake(e.target.value)}
                />
                <TextField
                    label="Search Country"
                    variant="outlined"
                    size="small"
                    sx={{ p: 1, m: 1 }}
                    onChange={(e) => handleSearchCountry(e.target.value)}
                />
                <TableContainer>
                    <Table stickyHeader size="small" sx={{ p: 0, m: 0 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography color="primary.main">
                                        Name
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography color="primary.main">
                                        Geo
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography color="primary.main">
                                        Size
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography color="primary.main">
                                        CMD
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filtered
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row: any) => (
                                    <TableRow
                                        key={'i' + rand() + rand()}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell>{row.lName}</TableCell>
                                        <TableCell>
                                            <Flag
                                                countriesList={row.countries}
                                            />
                                            {row.countries}
                                        </TableCell>
                                        <TableCell>{row.continent}</TableCell>
                                        <TableCell>
                                            <ButtonGroup
                                                variant="text"
                                                aria-label="text button group"
                                            >
                                                <Button
                                                    onClick={() =>
                                                        handleMap(row)
                                                    }
                                                >
                                                    <MapIcon />
                                                </Button>
                                                <Button
                                                    onClick={() =>
                                                        handleDetails(row)
                                                    }
                                                >
                                                    <VisibilityIcon />
                                                </Button>
                                                <Button
                                                    onClick={() =>
                                                        handleEdit(row)
                                                    }
                                                >
                                                    <EditIcon />
                                                </Button>
                                                <Button
                                                    onClick={() =>
                                                        handleDelete(row)
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={filtered.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
});
