import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';
import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import React, { useEffect, useRef } from 'react';
import { LakeObj } from './types';

am4core.useTheme(am4themes_animated);

const LakeMapGraphic: React.FC<{ row: LakeObj }> = ({ row }): JSX.Element => {
    const chart: any = useRef<any>(null);

    useEffect(() => {
        chart.current = am4core.create('chartdiv', am4maps.MapChart);
        chart.current.geodata = am4geodata_worldLow;
        chart.current.projection = new am4maps.projections.Mercator();

        // Zoom control
        chart.current.zoomControl = new am4maps.ZoomControl();

        // Set map definition
        chart.geodata = am4geodata_worldLow;

        // Disable amCharts logo if it exists
        chart.current.logo.disabled = true;

        chart.current.homeZoomLevel = 2;
        chart.current.homeGeoPoint = {
            latitude: parseInt(row.gLat),
            longitude: parseInt(row.gLon),
        };

        // Data for map
        const lakeData = [
            {
                id: 'lake',
                title: row.lName,
                latitude: parseFloat(row.gLat),
                longitude: parseFloat(row.gLon),
            },
        ];

        // The world
        const worldPolygonSeries = chart.current.series.push(
            new am4maps.MapPolygonSeries()
        );
        worldPolygonSeries.useGeodata = true;
        worldPolygonSeries.fillOpacity = 1;

        // Exclude Antarctica
        worldPolygonSeries.exclude = ['AQ'];

        // Create a series for the marker
        const markerSeries = chart.current.series.push(
            new am4maps.MapImageSeries()
        );

        // Add a marker at the specified latitude and longitude
        const marker = markerSeries.mapImages.create();
        marker.latitude = parseFloat(row.gLat);
        marker.longitude = parseFloat(row.gLon);
        marker.tooltipText = row.lName;

        // Add a circle to the marker
        const circle = marker.createChild(am4core.Circle);
        circle.radius = 2;
        //circle.fillOpacity = 0.3;
        circle.fill = am4core.color('#4285F4'); // Use the desired color
        circle.strokeOpacity = 0;
        //circle.nonScaling = true;
        circle.tooltipText = row.lName;

        const animateCircle = () => {
            // Animate the circle's scale and opacity
            circle
                .animate(
                    [
                        { property: 'scale', from: 1, to: 16 },
                        { property: 'opacity', from: 0.8, to: 0.1 },
                        { property: 'scale', from: 16, to: 0.3 }, // Additional animation to return to original scale
                        { property: 'opacity', from: 0.1, to: 0.8 }, // Additional animation to return to original opacity
                    ],
                    3000, // Animation duration
                    am4core.ease.circleOut
                )
                .events.on('animationended', animateCircle);
        };

        // Initial call to start the animation
        animateCircle();

        return () => {
            chart.current.dispose();
        };
    }, [row]);
    const maxWidth = window.innerWidth >= 900 ? 900 : '95%';
    return (
        <>
            <div
                style={{
                    minWidth:
                        window.innerWidth - 75 < 950
                            ? window.innerWidth - 75
                            : 900,
                    padding: 5,
                }}
            >
                {row.lName || 'No Lake Selected'}

                {' - ' + row.countries}
                <div
                    id="chartdiv"
                    style={{
                        width: '100%',
                        height: '500px',
                        backgroundColor: '#93c8fa',
                        borderRadius: '10px',
                        border: '1px solid #4285F4',
                    }}
                ></div>
            </div>
        </>
    );
};

export default LakeMapGraphic;
