import React from 'react';
import DashboardTemplate from '../../components/Template/DashboardTemplate';
import CouList from './CouList';

export default React.memo((): JSX.Element => {
    return (
        <DashboardTemplate>
            <h3>Countries</h3>
            <CouList />
        </DashboardTemplate>
    );
});
