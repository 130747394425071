import { CountryObj } from '../features/countries/countries';

export const cleanCountry = (row: CountryObj) => {
    let country = row.Country;
    // only first get changed to lowercase remove and
    country =
        country.charAt(0).toLowerCase() + country.slice(1).replace(/&/g, '');
    country = country.replace(' Is.', ' Islands');
    if (country.includes('nited States')) {
        country = 'usa';
    } else if (country.includes('S Virgin Islands')) {
        country = 'usVirginIslands';
    } else if (country.includes('nited Arab Emirates')) {
        country = 'uae';
    } else if (country.includes('nited Kingdom')) {
        country = 'uk';
    } else if (country.includes('Kitts')) {
        country = 'stKittsNevis';
    } else if (country.includes('ongo, Republic of the')) {
        country = 'congo';
    } else if (country.includes('ongo, Democratic Republic')) {
        country = 'congoDR';
    } else if (country.includes('orea, South')) {
        country = 'southKorea';
    } else if (country.includes('orea, North')) {
        country = 'northKorea';
    } else if (
        country.toString().includes(', the') ||
        country.toString().includes(', The')
    ) {
        country = country.toString().toLowerCase().replace(', the', '');
    } else {
        country = country.replace(/\s/g, '');
    }

    return country;
};
