import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MapIcon from '@mui/icons-material/Map';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PortraitIcon from '@mui/icons-material/Portrait';
import ListAltIcon from '@mui/icons-material/ListAlt';
import WavesIcon from '@mui/icons-material/Waves';
import PublicIcon from '@mui/icons-material/Public';
import List from '@mui/material/List';

import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { SnackBarType } from '../../../features/snackbar/snackbar';
import { addSnackbarMsg } from '../../../features/snackbar/snackbarSlice';

export const LeftNav = () => {
    const snackbar: SnackBarType = useAppSelector((state) => state.snackbar);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleLink = (link: string) => {
        dispatch(
            addSnackbarMsg({ msg: 'Navigating to ' + link, severity: 'info' })
        );

        navigate(link);
    };

    const listItems = [
        {
            label: 'Dashboard',
            route: '/dashboard',
            icon: <DashboardIcon />,
        },
        {
            label: 'Lakes',
            route: '/lakes',
            icon: <WavesIcon />,
        },
        {
            label: 'Countries',
            route: '/countries',
            icon: <PublicIcon />,
        },
        {
            label: 'Map',
            route: '/map',
            icon: <MapIcon />,
        },
        {
            label: 'Todo List',
            route: '/todo',
            icon: <ListAltIcon />,
        },
        {
            label: 'App Users',
            route: '/users',
            icon: <PortraitIcon />,
        },
    ];

    return (
        <div>
            <List component="nav">
                {listItems.map((item, index) => (
                    <React.Fragment key={index}>
                        <ListItemButton onClick={() => handleLink(item.route)}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.label} />
                        </ListItemButton>
                    </React.Fragment>
                ))}
            </List>
        </div>
    );
};
