import React from 'react';
import { CountriesState, CountryObj } from '../features/countries/countries';
import { useAppSelector } from '../app/hooks';

interface FlagProps {
    countriesList: string | undefined;
    cc2?: string | undefined;
}

export const returnFlag = (
    country: string,
    countries: CountryObj[],
    cc2: string | undefined
) => {
    try {
        if (cc2 !== undefined) return cc2;

        let tmp = '';
        if (country === 'USA') tmp = 'US';
        else if (country === 'UK') tmp = 'GB';
        else if (country === 'Congo') tmp = 'CG';
        else if (country === 'Congo (DRC)') tmp = 'CD';
        else if (country === 'Congo (Republic)') tmp = 'CG';
        else if (country === 'Czech Republic') tmp = 'CZ';
        else if (country === 'Czech') tmp = 'CZ';
        else if (country === 'Côte d’Ivoire') tmp = 'CI';
        else if (country === `Cote d'Ivoire`) tmp = 'CI';
        else if (country === 'Congo ( Dem. Rep. )') tmp = 'CG';
        else {
            const results = countries.filter(
                (c: CountryObj) => country === c.Country
            );
            tmp = results[0].cc2 !== undefined ? results[0].cc2 : 'US';
        }
        return tmp;
    } catch (e) {
        //console.log('returnFlag error: ', e);
        return '00';
    }
};

export default function Flag(props: FlagProps): JSX.Element {
    const { countriesList = 'id', cc2 } = props;
    const countries: CountriesState = useAppSelector(
        (state: any) => state.countries
    );
    // check to see if countriesList has a coma
    const couArr: string[] = countriesList.toString().includes(',')
        ? countriesList.split(',')
        : [countriesList];

    let cc2Arr: string[] = cc2 ? [cc2] : [];
    if (!cc2Arr[0]) {
        couArr.map(async (c: string) => {
            let nextOne = '';
            nextOne = returnFlag(c.trim(), countries.arr, cc2);
            cc2Arr = [...cc2Arr, nextOne];
        });
    }

    return (
        <>
            {cc2Arr.map((cc2: string) => {
                return (
                    <img
                        key={cc2}
                        src={`/img/flags/${cc2.toString().toLowerCase()}.png`}
                        alt={cc2}
                        style={{
                            width: 35,
                            height: 23,
                            marginLeft: 10,
                            marginRight: 10,
                            marginTop: 0,
                            marginBottom: 0,
                            padding: 0,
                        }}
                    />
                );
            })}
        </>
    );
}
