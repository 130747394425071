import { useEffect, useMemo, useState } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';

const mapStyle = { width: 500, height: 500 };

export default function Home(props: { lake: any }): JSX.Element {
    const lake = props.lake && props.lake.lake ? props.lake.lake : props.lake;
    const lat = lake ? parseFloat(lake.gLat) : 1.305385;
    const lng = lake ? parseFloat(lake.gLon) : 30.923029;
    const zoom = lake ? parseInt(lake.gMapZoom) : 10;
    const [mapInfo, setMapInfo] = useState(null);
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_API_KEY_MAPS || '999999',
    });

    const handleMapLoad = (map: any) => {
        setTimeout(() => {
            setMapInfo(map);
        }, 100);
    };

    const center = useMemo(() => ({ lat, lng }), []);

    useEffect(() => {
        console.log('mapInfo', mapInfo);
    }, []);

    if (loadError) return <div>Error loading maps</div>;
    else if (!isLoaded) return <div>Loading...</div>;
    else
        return (
            <div style={mapStyle}>
                <GoogleMap
                    zoom={zoom}
                    center={center}
                    mapContainerStyle={mapStyle}
                    onLoad={handleMapLoad}
                >
                    {!mapInfo ? (
                        <div>Loading...</div>
                    ) : (
                        <Marker
                            position={center}
                            visible={true}
                            zIndex={100}
                            title={lake.lName}
                        />
                    )}
                </GoogleMap>
            </div>
        );
}
