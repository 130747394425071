import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import countriesReducer from '../features/countries/countriesSlice';
import dialogReducer from '../features/dialog/dialogSlice';
import lakesReducer from '../features/lakes/lakesSlice';
import snackbarReducer from '../features/snackbar/snackbarSlice';
import todoReducer from '../features/todo/todoSlice';
import usersReducer from '../features/users/usersSlice';
import sessionReducer from '../features/session/sessionSlice';

export const store = configureStore({
    reducer: {
        countries: countriesReducer,
        dialog: dialogReducer,
        lakes: lakesReducer,
        snackbar: snackbarReducer,
        todo: todoReducer,
        users: usersReducer,
        session: sessionReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
