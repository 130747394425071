import { ContactsOutlined } from '@mui/icons-material';
import Box from '@mui/material/Box';
import React, { useEffect, useRef, useState } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useAppSelector } from '../../app/hooks';
import { apiPost } from '../../utilities/ApiRequest';
import Loading from '../../components/Loading';
import { LakeObj, LakeState } from './types';
import { SessionState } from '../../features/session/session.d';
import LakeRankView from './Tabs/LakesRankView';
import LakeMapEdit from './Tabs/LakeMapEdit';
import LakeTxtEdit from './Tabs/LakeTxtEdit';
import Flag from '../../widgets/Flag';

interface LakeDetailsProps {
    params: {
        _id: string;
        lName: string;
    };
}

export default function LakeEdit({ params }: LakeDetailsProps): JSX.Element {
    const { _id, lName } = params;

    const [value, setValue] = useState<string>('1');
    const init = useRef<boolean>(false);
    const session: SessionState = useAppSelector(
        (state: { session: any }) => state.session
    );
    const token = session.user.token;
    const lakes: LakeState = useAppSelector(
        (state: { lakes: any }) => state.lakes
    );

    const lake = lakes.arr.find((lake: LakeObj) => lake._id === _id);
    const countries = lake && lake.countries ? lake.countries : '';

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <div style={{ padding: 10 }}>
            <h3>
                Edit: {lName} {countries}
                <Flag countriesList={countries} />
            </h3>

            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange}>
                            <Tab label="Map" value="1" />
                            <Tab label="Details" value="2" />
                            <Tab label="Rank" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <LakeMapEdit lake={lake} />
                    </TabPanel>
                    <TabPanel value="2">
                        <LakeTxtEdit lake={lake} />
                    </TabPanel>
                    <TabPanel value="3">
                        <LakeRankView lake={lake} />
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    );
}
