import React from 'react';
import DashboardTemplate from '../../components/Template/DashboardTemplate';

export default React.memo((): JSX.Element => {
    return (
        <DashboardTemplate>
            <h3>Todo</h3>
        </DashboardTemplate>
    );
});
