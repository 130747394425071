import * as React from 'react';
import '../App.css';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';

import SnackStack from '../components/Snackbar/SnackStack';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { setSession } from '../features/session/sessionSlice';
import { isValidEmail, isValidPassword } from '../utilities/validate';
import { addSnackbarMsg } from '../features/snackbar/snackbarSlice';
import { SnackbarState } from '../features/snackbar/snackbar';
import { msg } from '../utilities/gen';
import { apiPost } from '../utilities/ApiRequest';
import { SessionState } from '../features/session/session';

const OuterDiv = styled('div')({
    height: '100vh', // 100% of the viewport height
    backgroundImage: 'url(./img/login_bg.jpeg)', // Replace with your actual image path
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
});

export default function Login() {
    const navigate = useNavigate();
    const dis = useAppDispatch();
    const session: SessionState = useAppSelector((state) => state.session);
    const snackbar: SnackbarState = useAppSelector((state) => state.snackbar);

    const [loading, setLoading] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            event.preventDefault();
            setLoading(true);
            const data = new FormData(event.currentTarget);

            const email = data.get('email') || '';
            const password = data.get('password');

            if (isValidEmail(data.get('email')) && isValidPassword(password)) {
                dis(addSnackbarMsg(msg(`Testing Credentials...`, 'info')));
                const res = await apiPost('/user_login', { email, password });

                if (res.data.err) {
                    setLoading(false);
                    dis(addSnackbarMsg(msg(`login failed: ${email}`, 'error')));
                } else {
                    const user = {
                        email,
                        token: res.data.token,
                    };
                    dis(setSession({ ...session, user }));
                    dis(addSnackbarMsg(msg(`Login success `, 'success')));
                    navigate(`/dashboard`);
                }
            } else {
                setLoading(false);
                dis(
                    addSnackbarMsg(
                        msg(`Please enter valid email & password `, 'error')
                    )
                );
            }
        } catch (err) {
            console.log(err);
        }
    };

    React.useEffect(() => {
        //console.log('UE');
    }, [snackbar, session]);

    return (
        <OuterDiv>
            <div className="vertical-center center-outer">
                <div className="center-inner">
                    <Paper>
                        <Container
                            component="main"
                            maxWidth="lg"
                            sx={{
                                p: 2,
                            }}
                        >
                            <CssBaseline />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <Avatar sx={{ m: 1, bgcolor: '#fff' }}>
                                    <img src="/icon32x32.png" alt="logo" />
                                </Avatar>
                                <Box
                                    component="form"
                                    onSubmit={handleSubmit}
                                    noValidate
                                    sx={{ mt: 3 }}
                                >
                                    <TextField
                                        size="small"
                                        defaultValue={''}
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        autoFocus
                                    />
                                    <FormControl
                                        sx={{ mt: 2 }}
                                        variant="outlined"
                                        fullWidth
                                    >
                                        <InputLabel htmlFor="outlined-adornment-password">
                                            Password
                                        </InputLabel>
                                        <OutlinedInput
                                            size="small"
                                            required
                                            fullWidth
                                            id="password"
                                            name="password"
                                            defaultValue={''}
                                            autoComplete="current-password"
                                            type={
                                                showPassword
                                                    ? 'text'
                                                    : 'password'
                                            }
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={
                                                            handleClickShowPassword
                                                        }
                                                        onMouseDown={
                                                            handleMouseDownPassword
                                                        }
                                                        edge="end"
                                                    >
                                                        {showPassword ? (
                                                            <VisibilityOff />
                                                        ) : (
                                                            <Visibility />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                            label="Password"
                                        />
                                    </FormControl>

                                    <Box
                                        sx={{
                                            position: 'relative',
                                            marginBottom: 2,
                                        }}
                                    >
                                        <Button
                                            size="small"
                                            type="submit"
                                            fullWidth
                                            variant="contained"
                                            sx={{ mt: 2, mb: 2, height: 40 }}
                                            disabled={loading}
                                            onClick={() => handleSubmit}
                                        >
                                            Sign In
                                        </Button>
                                        {loading && (
                                            <CircularProgress
                                                size={18}
                                                sx={{
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    marginTop: '-12px',
                                                    marginLeft: '-12px',
                                                }}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Container>
                    </Paper>
                </div>
            </div>
            <SnackStack />
        </OuterDiv>
    );
}
