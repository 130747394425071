import React from 'react';
import { useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { addSnackbarMsg } from '../../features/snackbar/snackbarSlice';
import { msg } from '../../utilities/gen';
import { LakeState } from './types';
import DashboardTemplate from '../../components/Template/DashboardTemplate';
import LakeList from './LakeList';

export default React.memo((): JSX.Element => {
    const dis = useAppDispatch();
    const session: any = useAppSelector((state: any) => state.session);
    const lakes: LakeState = useAppSelector((state: any) => state.lakes);
    const speed = session.speed * 1000;
    const init = useRef<boolean>(false);

    setTimeout(() => {
        if (!init.current) {
            init.current = true;
            dis(addSnackbarMsg(msg(`Loading Lakes...`, 'info')));
            setTimeout(
                () => {
                    dis(addSnackbarMsg(msg(`Lakes Loaded`, 'success')));
                },
                speed * 2 + 20
            );
        }
    }, speed + 10);

    React.useEffect(() => {
        // refresh the list
        console.log('UE Lakes');
    }, [lakes]);

    return (
        <DashboardTemplate>
            <h2>Lakes</h2>
            <LakeList />
        </DashboardTemplate>
    );
});
