import React from 'react';
import DashboardTemplate from '../../components/Template/DashboardTemplate';
import UsersDisplay from './UsersDisplay';

export default React.memo((): JSX.Element => {
    return (
        <DashboardTemplate>
            <h3>Users</h3>
            <UsersDisplay />
        </DashboardTemplate>
    );
});
