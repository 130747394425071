import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { CountriesState } from './countries';

const initialState: CountriesState = {
    arr: [],
};

export const countriesSlice = createSlice({
    name: 'countries',
    initialState,

    reducers: {
        setCountries: (state: CountriesState, action: PayloadAction<any>) => {
            try {
                state.arr = action.payload.arr
                    ? action.payload.arr
                    : action.payload;
            } catch (error) {
                console.log(error);
            }
        },
        clearCountries: (state) => initialState,
    },
});

export const { setCountries, clearCountries } = countriesSlice.actions;

export const selectCountries = (state: RootState) => state.countries;

export default countriesSlice.reducer;
