import DashboardTemplate from '../components/Template/DashboardTemplate';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { CountriesState } from '../features/countries/countries';
import { LakeState } from './Lakes/types';
import { UsersState } from '../features/users/users';
import Button from '@mui/material/Button';

import { addSnackbarMsg } from '../features/snackbar/snackbarSlice';
import { apiPost } from '../utilities/ApiRequest';
import { clearLakes, setLakes } from '../features/lakes/lakesSlice';
import { useEffect, useRef, useState } from 'react';
import {
    clearCountries,
    setCountries,
} from '../features/countries/countriesSlice';
import { clearUsers, setUsers } from '../features/users/usersSlice';
import LoadBox from '../components/LoadBox';

import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { msg } from '../utilities/gen';

const excludeFields = (dataArray: any[], fieldsToExclude: string[]) => {
    return dataArray.map((obj) => {
        const newObj: any = {};
        for (const key in obj) {
            if (!fieldsToExclude.includes(key)) {
                newObj[key] = obj[key];
            }
        }
        return newObj;
    });
};
// this function is set up to ensure that certain fields appear in the data even if empty
const includeField = (dataArray: any[], mustHaveAttributes: string[]) => {
    return dataArray.map((obj) => {
        const newObj: any = { ...obj };

        mustHaveAttributes.forEach((attribute) => {
            // Check if the attribute is undefined or null, and then add it
            if (newObj[attribute] === undefined || newObj[attribute] === null) {
                newObj[attribute] = ''; // Add the attribute with a blank value if it's undefined or null
            }
        });
        return newObj;
    });
};
export default function Dashboard(): JSX.Element {
    const dis = useAppDispatch();
    const fetchArr = useRef<string[]>([]);
    const countries: CountriesState = useAppSelector(
        (state) => state.countries
    );
    const lakes: LakeState = useAppSelector((state) => state.lakes);
    const users: UsersState = useAppSelector((state) => state.users);
    const session: any = useAppSelector((state) => state.session);
    const token = session.user.token;

    const [init, initSet] = useState<boolean>(false);

    const handleReloadCountries = () => dis(clearCountries());
    const handleReloadLakes = () => dis(clearLakes());
    const handleReloadUsers = () => dis(clearUsers());

    const fetcher = async (
        obj: any,
        setFunction: ActionCreatorWithPayload<any>,
        postUrl: string,
        id: string | boolean,
        exclude: string[],
        include: string[]
    ) => {
        if (!obj.init && !fetchArr.current.includes(postUrl)) {
            fetchArr.current.push(postUrl);
            const res = await apiPost(postUrl, { token });

            if (!res.data.err && !obj.init) {
                let modifiedData = excludeFields(res.data.data || [], exclude);
                modifiedData = includeField(modifiedData, include);
                dis(setFunction(modifiedData));
            } else console.log('could not load from: ' + postUrl);
        }
    };
    {
        setTimeout(() => {
            fetcher(
                countries,
                setCountries,
                '/cou_list',
                'id',
                [],
                ['cc2', 'Code']
            );
            fetcher(
                lakes,
                setLakes,
                '/lakes_list',
                'id',
                ['other1', 'other3', 'other4', 'Lon', 'Lat', 'beenCounted'],
                []
            );
            fetcher(users, setUsers, '/user_list', 'id', [], []);
        }, 100);
    }
    // git resett
    const allInit = (): boolean => {
        return countries &&
            countries.arr[0] &&
            lakes &&
            lakes.arr[0] &&
            users &&
            users.arr[0]
            ? true
            : false;
    };

    const snack = () => {
        dis(addSnackbarMsg(msg(`Data Loaded`, 'success')));
    };

    useEffect(() => {
        if (!init && allInit()) {
            initSet(true);
            dis(addSnackbarMsg(msg(`Data Loaded`, 'success')));
        }
    }, [countries, lakes, users]);

    return (
        <DashboardTemplate>
            <h3>Loader Starts here</h3>

            <LoadBox
                obj={countries}
                title="Countries"
                handleFunction={handleReloadCountries}
            />

            <LoadBox
                obj={lakes}
                title="Lakes"
                handleFunction={handleReloadLakes}
            />

            <LoadBox
                obj={users}
                title="Users"
                handleFunction={handleReloadUsers}
            />
            <Button onClick={snack}>Snack</Button>
        </DashboardTemplate>
    );
}
