import './App.css';
import { Suspense, useMemo, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { darkTheme, lightTheme } from './components/themes/Theme';
import { useAppSelector } from './app/hooks';
import { SessionState } from './features/session/session';
import ProtectedRoute from './utilities/ProtectedRoute';
import Lakes from './pages/Lakes';
import Login from './pages/Login';
import Profile from './pages/Profile';
import CssBaseline from '@mui/material/CssBaseline';
import Countries from './pages/Countries';
import Todo from './pages/Todo';
import Users from './pages/Users';
import Settings from './pages/Settings';
import Dashboard from './pages/Dashboard';
import Map from './pages/Map';

function App() {
    const session: SessionState = useAppSelector((state) => state.session);

    useEffect(() => {
        /*toggle */
    }, [session.darkMode]);

    return (
        <div style={{ minHeight: 800 }}>
            <Suspense fallback={<div>Loading...</div>}>
                <ThemeProvider
                    theme={!session.darkMode ? lightTheme : darkTheme}
                >
                    <CssBaseline />
                    <Routes>
                        <Route
                            path="/"
                            element={<Navigate replace to="/login" />}
                        />
                        <Route path="/login" element={<Login />} />

                        <Route element={<ProtectedRoute />}>
                            <Route path="/countries" element={<Countries />} />
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route path="/lakes" element={<Lakes />} />
                            <Route path="/map" element={<Map />} />
                            <Route path="/profile" element={<Profile />} />
                            <Route path="/settings" element={<Settings />} />
                            <Route path="/todo" element={<Todo />} />
                            <Route path="/users" element={<Users />} />
                        </Route>
                    </Routes>
                </ThemeProvider>
            </Suspense>
        </div>
    );
}

export default App;
