import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { DialogState } from '../../features/dialog/dialog';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { clearDialog } from '../../features/dialog/dialogSlice';
import DialogLakeDetails from '../../pages/Lakes/DialogLakeDetails';
import DialogLakeEdit from '../../pages/Lakes/DialogLakeEdit';
import DialogUseEdit from '../../pages/Users/DialogUseEdit';
import DialogCountryEdit from '../../pages/Countries/DialogCountryEdit';
import DialogCountryDetails from '../../pages/Countries/DialogCountryDetails';
import DialogCountryMap from '../../pages/Countries/DialogCountryMap';
import DialogLakeMapGraphic from '../../pages/Lakes/DailogLakeMapGraphic';
// import DialogLakeGlobe from '../../pages/Lakes/DialogLakeGlobe';
// import DialogMapSpin from '../../pages/Lakes/DialogMapSpin';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default React.memo((): JSX.Element => {
    const dis = useAppDispatch();
    const dialog: DialogState = useAppSelector((state) => state.dialog);
    const { open, content, params } = dialog;

    const handleClose = () => dis(clearDialog());

    const compPicker = (content: string, params: any): any => {
        switch (content) {
            case 'Details':
                return <DialogLakeDetails params={params} />;
            case 'Edit':
                return <DialogLakeEdit params={params} />;
            case 'UseEdit':
                return <DialogUseEdit params={params} />;
            case 'CountryEdit':
                return <DialogCountryEdit country={params.row} />;
            case 'CountryDetails':
                return <DialogCountryDetails country={params.row} />;
            case 'CountryMap':
                return <DialogCountryMap row={params.row} />;
            case 'MapLakeGraphic':
                return <DialogLakeMapGraphic row={params.row} />;
            // case 'LakeGlobe':
            //     return <DialogLakeGlobe row={params.row} />;

            default:
                return <DialogLakeDetails params={params} />;
        }
    };

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                style={{ minWidth: '90vw' }}
                maxWidth="xl"
            >
                {compPicker(content, params)}
            </Dialog>
        </>
    );
});
