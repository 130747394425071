import Box from '@mui/material/Box';
import React, { useState } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useAppSelector } from '../../app/hooks';
import { LakeObj, LakeState } from './types';
import LakeMapView from './Tabs/LakeMapView';
import LakeDetailsView from './Tabs/LakeDetailsView';
import LakeRankView from './Tabs/LakesRankView';
import Flag from '../../widgets/Flag';

interface LakeDetailsProps {
    params: {
        _id: string;
        lName: string;
    };
}

export default function LakeDetails({ params }: LakeDetailsProps): JSX.Element {
    const { _id, lName } = params;

    const [value, setValue] = useState<string>('1');

    const lakes: LakeState = useAppSelector(
        (state: { lakes: any }) => state.lakes
    );

    const lake = lakes.arr.find((lake: LakeObj) => lake._id === _id);
    const countries = lake && lake.countries ? lake.countries : '';

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <div style={{ padding: 10 }}>
            <h3>Details: {lName} </h3>
            <Flag countriesList={countries} />
            {countries}
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange}>
                            <Tab label="Map" value="1" />
                            <Tab label="Details" value="2" />
                            <Tab label="Rank" value="3" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <LakeMapView lake={lake} />
                    </TabPanel>
                    <TabPanel value="2">
                        <LakeDetailsView lake={lake} />
                    </TabPanel>
                    <TabPanel value="3">
                        <LakeRankView lake={lake} />
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    );
}
