import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';

const initialState = {
    msg: 'Welcome',
    severity: 'info',
};

export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        addSnackbarMsg: (state, action: PayloadAction<any>) => {
            // create a new snackbar message
            const { msg, severity } = action.payload;
            if (state.msg !== msg) {
                state.msg = msg;
                state.severity = severity;
            }
        },
        removeSnackbarMsg: (state, action: PayloadAction<any>) => {
            // remove the snackbar message at address 0
        },
        clearSnackbar: () => initialState,
        // Use the PayloadAction type to declare the contents of `action.payload`
    },
});

export const { addSnackbarMsg, removeSnackbarMsg, clearSnackbar } =
    snackbarSlice.actions;

export const selectSnackbar = (state: RootState) => state.snackbar;

export default snackbarSlice.reducer;
