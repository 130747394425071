import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MapIcon from '@mui/icons-material/Map';
import Typography from '@mui/material/Typography';

import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { addSnackbarMsg } from '../../features/snackbar/snackbarSlice';
import { buildData, dialogParams, msg } from '../../utilities/gen';

import Flag from '../../widgets/Flag';
import { ButtonGroup, TextField } from '@mui/material';
import { setDialog } from '../../features/dialog/dialogSlice';
import { setCountries } from '../../features/countries/countriesSlice';

import { SessionState } from '../../features/session/session';
import { CountriesState, CountryObj } from '../../features/countries/countries';
import { apiPost } from '../../utilities/ApiRequest';

export default React.memo((): JSX.Element => {
    const dis = useAppDispatch();
    const session: SessionState = useAppSelector((state) => state.session);
    const { token } = session.user;
    const countries: CountriesState = useAppSelector(
        (state) => state.countries
    );
    const initialCountries = countries.arr; // Assuming countries.arr is your initial array
    const sortedCountries = [...initialCountries].sort((a: any, b: any) =>
        a.Country.localeCompare(b.Country)
    );
    const [page, setPage] = React.useState(0);
    const [data, dataSet] = React.useState<any>(undefined);
    const [rowsPerPage, setRowsPerPage] = React.useState(50);
    const [filtered, setFiltered] = React.useState<any>(sortedCountries);

    const datas: number[] = [];
    const labels: string[] = [];

    const pop = (n: any) => {
        const nf = new Intl.NumberFormat('en-US');
        return nf.format(n) ? nf.format(n) : n;
    };

    const handleChangePage = (event: any, newPage: number) => setPage(newPage);

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSearchCountry = (search: string) => {
        setPage(0);
        setFiltered(
            search.length < 2
                ? countries.arr
                : countries.arr.filter((e: any) => e.Country.includes(search))
        );
    };

    countries.arr.map((cou: any) => labels.push(cou.name));

    if (!data) dataSet(buildData(labels, datas));

    const handleDetails = (row: CountryObj) => {
        const { _id, Country } = row;
        dis(
            setDialog(
                dialogParams(true, 'Country: ' + Country, 'CountryDetails', {
                    _id,
                    Country,
                    row,
                })
            )
        );

        dis(addSnackbarMsg(msg(`Loading ${Country} Details`, 'info')));
    };

    const handleMap = (row: CountryObj) => {
        const { Country } = row;
        dis(addSnackbarMsg(msg(`Loading Map for ${Country}`, 'info')));

        dis(
            setDialog(
                dialogParams(true, 'Country: ' + Country, 'CountryMap', {
                    row,
                })
            )
        );
    };

    const handleEdit = (row: CountryObj) => {
        const { _id, Country } = row;
        dis(
            setDialog(
                dialogParams(true, 'Country: ' + Country, 'CountryEdit', {
                    _id,
                    Country,
                    row,
                })
            )
        );

        dis(addSnackbarMsg(msg(`Loading ${Country} for Edit`, 'warning')));
    };

    const handleDelete = async (row: CountryObj) => {
        console.log(countries.arr);
        if (window.confirm('Are you sure you want to delete this?')) {
            dis(
                setCountries({
                    ...countries,
                    arr: countries.arr.filter((e: any) => e._id !== row._id),
                })
            );
            dis(
                addSnackbarMsg(msg(`Delete Country ${row.Country} `, 'warning'))
            );
            const _id = row._id;
            const collection = 'countries';
            const res = await apiPost('/doc_delete', {
                token,
                collection,
                _id,
            });

            const severity = res.data.err ? 'error' : 'success';
            const resp = res.data.msg
                ? res.data.msg
                : `Country ${row.Country} updated `;
            setTimeout(() => dis(addSnackbarMsg(msg(resp, severity))), 500);
        } else {
            dis(addSnackbarMsg(msg(`Could not delete ${row.Country}`, 'info')));
            return false;
        }
    };

    return (
        <>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TextField
                    label="Search Country"
                    variant="outlined"
                    size="small"
                    sx={{ p: 1, m: 1 }}
                    onChange={(e) => handleSearchCountry(e.target.value)}
                />
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography color="primary.main">
                                        Country
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography color="primary.main">
                                        Abbr
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography color="primary.main">
                                        Region
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography color="primary.main">
                                        Population
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography color="primary.main">
                                        CMD
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filtered
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row: any) => (
                                    <TableRow
                                        key={row._id}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell>
                                            <Flag
                                                countriesList={row.Country}
                                                cc2={row.cc2 || 'XX'}
                                                key={'f' + row._id}
                                            />{' '}
                                            {row.Country}
                                        </TableCell>
                                        <TableCell>
                                            {row.cc2 + ' '}
                                            {row.Code}
                                        </TableCell>
                                        <TableCell>{row.Region}</TableCell>
                                        <TableCell>
                                            {pop(row.Population)}
                                        </TableCell>
                                        <TableCell>
                                            <ButtonGroup
                                                variant="text"
                                                aria-label="text button group"
                                            >
                                                <Button
                                                    onClick={() =>
                                                        handleDetails(row)
                                                    }
                                                >
                                                    <VisibilityIcon />
                                                </Button>
                                                <Button
                                                    onClick={() =>
                                                        handleEdit(row)
                                                    }
                                                >
                                                    <EditIcon />
                                                </Button>
                                                <Button
                                                    onClick={() =>
                                                        handleMap(row)
                                                    }
                                                >
                                                    <MapIcon />
                                                </Button>
                                                {/* <Button
                                                    onClick={() =>
                                                        handleDelete(row)
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </Button> */}
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50, 100]}
                    component="div"
                    count={countries.arr.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
});
