import { rand } from '../../utilities/gen';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';

export default function (props: { country: any }): JSX.Element {
    return (
        <>
            <div style={{ width: '100%', padding: 3 }}>
                Details
                <Table sx={{ minWidth: 300, p: 1, m: 0 }}>
                    <TableContainer>
                        {
                            // iteriate over the lake object and display the data
                            Object.keys(props.country).map((key) => {
                                return (
                                    <TableRow key={'k' + rand()}>
                                        <TableCell
                                            style={{
                                                backgroundColor: '#ccc',
                                                textAlign: 'right',
                                            }}
                                        >
                                            {key}
                                        </TableCell>
                                        <TableCell>
                                            {props.country[key]}
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableContainer>
                </Table>
            </div>
        </>
    );
}
